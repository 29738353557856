import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { initAndDestroy } from 'src/app/shared/animations';

import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';

@Component({
  selector: 'tmt-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrl: './off-canvas.component.scss',
  providers: [ActionPanelService, NavigationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [initAndDestroy],
})
export class OffCanvasComponent implements OnInit {
  public breakPointClassName: string;
  public excludeClickOutside =
    '.notification-list, .modal-dialog, tmt-popup-container, .menubar-item-tooltip, ';

  private destroyRef = inject(DestroyRef);

  constructor(
    public offCanvasService: OffCanvasService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.offCanvasService.offCanvasWidth$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((width) => {
        this.breakPointClassName = 'offcanvas-body--sm';

        if (width >= 768) {
          this.breakPointClassName = 'offcanvas-body--md';
        }

        if (width >= 992) {
          this.breakPointClassName = 'offcanvas-body--lg';
        }

        if (width >= 1200) {
          this.breakPointClassName = 'offcanvas-body--xl';
        }

        this.cdr.markForCheck();
      });

    if (this.offCanvasService.offCanvasEntry.exclude) {
      this.excludeClickOutside += this.offCanvasService.offCanvasEntry.exclude;
    }
  }
}
