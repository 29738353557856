import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IssueTypeCardService } from 'src/app/settings-app/issue-types/card/issue-type-card.service';
import {
  IssueTypeMember,
  IssueTypeMemberType,
} from 'src/app/settings-app/issue-types/model/issue-type-member.model';
import { TranslateService } from '@ngx-translate/core';
import { IssueTypePermissionsPerformersComponent } from 'src/app/settings-app/issue-types/card/permissions/performers/performers.component';

@Component({
  selector: 'tmt-issue-type-permissions',
  templateUrl: './issue-type-permissions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTypePermissionsComponent implements OnInit {
  @ViewChildren(IssueTypePermissionsPerformersComponent)
  public performersComponents: IssueTypePermissionsPerformersComponent[];

  @Input() entityId: string;
  @Input() readonly: boolean;
  @Input() team: Partial<IssueTypeMember>[];
  @Input() isSaving$: BehaviorSubject<boolean>;

  private destroyRef = inject(DestroyRef);

  constructor(
    public issueTypeCardService: IssueTypeCardService,
    private fb: FormBuilder,
    private actionPanelService: ActionPanelService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setFormTeam(this.issueTypeCardService.members);
  }

  /** Saves members. */
  public saveMembers(): void {
    const team: {
      insert: Partial<IssueTypeMember>[];
      update: Partial<IssueTypeMember>[];
      delete: string[];
    } = this.getSavingArrays();

    if (!team.insert.length && !team.delete.length && !team.update.length) {
      this.isSaving$.next(false);
      this.actionPanelService.action('save').stop();
      this.notificationService.successLocal(
        'components.issueTypeCardService.messages.saved',
      );
      return;
    }

    const massOperationHelper = new MassOperationHelper(
      team.delete
        .map((id: string) =>
          this.dataService.collection('IssueTypeMembers').entity(id).delete(),
        )
        .concat(
          team.insert.map((item: Partial<IssueTypeMember>) =>
            this.dataService.collection('IssueTypeMembers').insert(item),
          ),
        )
        .concat(
          team.update.map((item: Partial<IssueTypeMember>) =>
            this.dataService
              .collection('IssueTypeMembers')
              .entity(item.id)
              .update(item),
          ),
        ),
      { takeUntil: takeUntilDestroyed(this.destroyRef) },
    );
    massOperationHelper
      .start()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((progress) => {
        if (progress === 100) {
          if (massOperationHelper.errors.length) {
            const uniqueErrors = new Set();
            massOperationHelper.errors.forEach((error) => {
              uniqueErrors.add(
                error.result?.message || 'shared2.messages.unknownError',
              );
            });
            uniqueErrors.forEach((error: string) => {
              this.notificationService.error(error);
            });
          } else if (
            massOperationHelper.completedCount ===
            team.insert.length + team.delete.length + team.update.length
          ) {
            this.notificationService.successLocal(
              'components.issueTypeCardService.messages.saved',
            );
            Object.keys(team).forEach((item) => (team[item] = []));
          }

          this.isSaving$.next(false);
          this.actionPanelService.action('save').stop();
          /** Updates team after saving. */
          this.team = [];
          this.issueTypeCardService.viewers.value.forEach((item) => {
            this.team.push(this.getTeamMember(item, 'Viewer'));
          });
          this.issueTypeCardService.editors.value.forEach((item) => {
            this.team.push(this.getTeamMember(item, 'Editor'));
          });
          this.issueTypeCardService.members = this.team;
        }
      });
  }

  /**
   * Gets arrays for saving.
   *
   * @returns object with array for insert, update and delete members.
   */
  public getSavingArrays(): {
    insert: Partial<IssueTypeMember>[];
    update: Partial<IssueTypeMember>[];
    delete: string[];
  } {
    const viewers = this.issueTypeCardService.viewers.value.map((viewer) => {
      viewer.type = IssueTypeMemberType.Viewer;
      return viewer;
    });
    const editors = this.issueTypeCardService.editors.value.map((editor) => {
      editor.type = IssueTypeMemberType.Editor;
      return editor;
    });
    const team = {
      insert: [],
      update: [],
      delete: [],
    };
    for (const item of [...viewers, ...editors]) {
      const foundTeamMember = this.team.find(
        (teamMember) => item.id === teamMember.id,
      );
      if (foundTeamMember) {
        if (
          item.performer.id !==
          (foundTeamMember.userId ||
            foundTeamMember.groupId ||
            foundTeamMember.permissionSetId ||
            foundTeamMember.role)
        ) {
          team.update.push({
            id: item.id,
            ...this.setItemPermissionId(
              item.performer.id,
              item.performer.type,
              'update',
              item.type,
            ),
          });
        }
      } else {
        team.insert.push({
          id: item.id,
          ...this.setItemPermissionId(
            item.performer.id,
            item.performer.type,
            'insert',
            item.type,
          ),
        });
      }
    }

    for (const item of this.team) {
      if (
        !this.issueTypeCardService.viewers.value.find(
          (teamItem) =>
            teamItem.id === item.id && item.type === IssueTypeMemberType.Viewer,
        ) &&
        !this.issueTypeCardService.editors.value.find(
          (teamItem) =>
            teamItem.id === item.id && item.type === IssueTypeMemberType.Editor,
        )
      ) {
        team.delete.push(item.id);
      }
    }

    return team;
  }

  /**
   * Sets members form array.
   *
   * @param members Issue type members.
   */
  public setFormTeam(members: Partial<IssueTypeMember>[]): void {
    this.issueTypeCardService.viewers.clear();
    this.issueTypeCardService.editors.clear();
    members.forEach((item) => {
      const group = this.fb.group({
        id: item.id,
        performer: {
          id: item.userId || item.groupId || item.permissionSetId || item.role,
          type: item.userId
            ? 'user'
            : item.groupId
              ? 'group'
              : item.permissionSetId
                ? 'permissionSet'
                : 'role',
          name:
            item.user?.name ??
            item.group?.name ??
            item.permissionSet?.name ??
            this.getRoleName(item.role),
        },
      });
      switch (item.type) {
        case IssueTypeMemberType.Editor:
          this.issueTypeCardService.editors.push(group);
          break;
        case IssueTypeMemberType.Viewer:
          this.issueTypeCardService.viewers.push(group);
          break;
      }
    });
  }
  /**
   * Sets id property according to permission type.
   *
   * @param id setting id.
   * @param type permission type.
   * @param methodName Method type(insert, update).
   * @param issueMemberType Issue member type.
   */
  private setItemPermissionId(
    id: string,
    type: string,
    methodName: string,
    issueMemberType: IssueTypeMemberType,
  ): Partial<IssueTypeMember> {
    let member: Partial<IssueTypeMember>;
    switch (methodName) {
      case 'insert':
        member = {
          isActive: true,
          issueTypeId: this.entityId,
          type: issueMemberType,
        };
        break;
      case 'update':
        member = {
          isActive: true,
          issueTypeId: this.entityId,
          groupId: null,
          userId: null,
          permissionSetId: null,
          role: null,
          type: issueMemberType,
        };
        break;
    }

    switch (type) {
      case 'group':
        member.groupId = id;
        break;
      case 'user':
        member.userId = id;
        break;
      case 'permissionSet':
        member.permissionSetId = id;
        break;
      case 'role':
        member.role = id;
        break;
    }

    return member;
  }

  /** Returns localized role name. */
  public getRoleName(role: string): string {
    return this.translate.instant(`shared2.props.role${role}`);
  }

  /**
   * Gets team member from form group value.
   *
   * @param item form group value item.
   * @param type team member type.
   */
  private getTeamMember(item: any, type: string): Record<string, string> {
    return {
      id: item.id,
      groupId: item.performer.type === 'group' ? item.performer.id : null,
      userId: item.performer.type === 'user' ? item.performer.id : null,
      permissionSetId:
        item.performer.type === 'permissionSet' ? item.performer.id : null,
      role: item.performer.type === 'role' ? item.performer.id : null,
      type,
      issueTypeId: this.entityId,
    };
  }
}
