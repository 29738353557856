export interface PnlRow {
  id: string;
  type: PnlRowType;
  header: string;
  groupId?: string | null;
  isExpanded?: boolean | null;
  isGroupExpanded?: boolean | null;
  cells: Array<PnlCell>;
}

export interface PnlCell {
  id: string;
  displayValue: any;
  deviatesFromRuleBase?: boolean;
  baseHint?: string;
  fn?: () => void;
}

export enum PnlRowType {
  row = 'row',
  separator = 'separator',
  group = 'group',
  groupItem = 'groupItem',
  expandableGroupItem = 'expandableGroupItem',
  grossMargin = 'grossMargin',
  extendedGrossMargin = 'extendedGrossMargin',
  profitability = 'profitability',
  extendedProfitability = 'extendedProfitability',
  workingCapital = 'workingCapital',
  outstandingCost = 'outstandingCost',
  costOfCapital = 'costOfCapital',
  corporateTax = 'corporateTax',
}
