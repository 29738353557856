import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';

import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';

import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';

import { FinancialAccountCreationComponent } from '../creation/financial-account-creation.component';

@Component({
  selector: 'tmt-expense-type-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialAccountListComponent implements OnInit {
  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private stateService: StateService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
  ) {
    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed())
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;
        this.actionPanelService.action('delete').isShown =
          row &&
          this.appService.checkEntityPermission(
            'FinancialAccount',
            PermissionType.Modify,
          );
      });
  }

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.financialAccountListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'FinancialAccount',
          PermissionType.Modify,
        ),
        handler: () =>
          this.modalService.open(FinancialAccountCreationComponent),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.financialAccount', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);
  }
}
