<tmt-form-header [name]="form.value?.name" />
<wp-loading-indicator [loading]="isLoading()" />
<wp-data-not-saved-indicator [form]="form" />

<form [formGroup]="form" [hidden]="isLoading()" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <div class="actions" [hidden]="readonly()">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving()"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
