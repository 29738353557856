import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { Feature } from '../models/enums/feature.enum';
import { environment } from 'src/environments/environment';

export const SETTINGS_APP_NAVIGATION: Navigation = {
  name: 'settings',
  applicationTitle: 'settings.navigation.header',
  groups: [
    {
      name: 'system',
      header: 'settings.navigation.system.header',
      items: [
        {
          header: 'settings.navigation.system.account.header',
          hint: 'settings.navigation.system.account.hint',
          state: 'settings.account',
          name: 'settings.account',
          allowedFn: (): boolean => !environment.onprem,
        },
        {
          header: 'settings.navigation.system.license.header',
          hint: 'settings.navigation.system.license.hint',
          state: 'settings.account',
          name: 'settings.account',
          allowedFn: (): boolean => environment.onprem,
        },
        {
          header: 'settings.navigation.system.settings.header',
          hint: 'settings.navigation.system.settings.hint',
          state: 'settings.settings',
          name: 'settings.settings',
        },
        {
          header: 'settings.navigation.system.imports.header',
          hint: 'settings.navigation.system.imports.hint',
          state: 'settings.imports',
          name: 'settings.imports',
        },
        {
          header: 'settings.navigation.system.historyLog.header',
          hint: 'settings.navigation.system.historyLog.hint',
          state: 'settings.historyLog',
          name: 'settings.historyLog',
          entityType: 'HistoryLogEntry',
        },
        {
          header: 'settings.navigation.users.loginLog.header',
          hint: 'settings.navigation.users.loginLog.hint',
          state: 'settings.loginLog',
          name: 'settings.loginLog',
          entityType: 'LoginLogEntry',
        },
      ],
    },
    {
      name: 'users',
      header: 'settings.navigation.users.header',
      items: [
        {
          name: 'settings.users',
          header: 'settings.navigation.users.users.header',
          hint: 'settings.navigation.users.users.hint',
          state: 'settings.users',
        },
        {
          name: 'settings.groups',
          header: 'settings.navigation.users.groups.header',
          hint: 'settings.navigation.users.groups.hint',
          state: 'settings.groups',
        },

        {
          header: 'settings.navigation.users.departments.header',
          hint: 'settings.navigation.users.departments.hint',
          state: 'settings.departments',
          name: 'settings.departments',
        },
        {
          header: 'settings.navigation.users.permissionSets.header',
          hint: 'settings.navigation.users.permissionSets.hint',
          state: 'settings.permissionSets',
          name: 'settings.permissionSets',
          entityType: 'PermissionSet',
        },
      ],
    },
    {
      name: 'resources',
      header: 'settings.navigation.resources.header',
      items: [
        {
          header: 'settings.navigation.users.resourcePools.header',
          hint: 'settings.navigation.users.resourcePools.hint',
          state: 'settings.resourcePools',
          name: 'settings.resourcePools',
        },
        {
          name: 'settings.levels',
          header: 'settings.navigation.users.levels.header',
          hint: 'settings.navigation.users.levels.hint',
          state: 'settings.levels',
          entityType: 'Level',
        },
        {
          name: 'settings.grades',
          header: 'settings.navigation.users.grades.header',
          hint: 'settings.navigation.users.grades.hint',
          state: 'settings.grades',
          entityType: 'Grade',
        },
        {
          name: 'settings.locations',
          header: 'settings.navigation.users.locations.header',
          hint: 'settings.navigation.users.locations.hint',
          state: 'settings.locations',
        },
        {
          header: 'settings.navigation.users.roles.header',
          hint: 'settings.navigation.users.roles.hint',
          state: 'settings.roles',
          name: 'settings.roles',
        },
        {
          header: 'settings.navigation.users.competences.header',
          hint: 'settings.navigation.users.competences.hint',
          state: 'settings.competences',
          name: 'settings.competences',
        },
        {
          header: 'settings.navigation.users.skills.header',
          hint: 'settings.navigation.users.skills.hint',
          state: 'settings.skills',
          name: 'settings.skills',
        },
      ],
    },
    {
      name: 'timeTracking',
      header: 'settings.navigation.timeTracking.header',
      items: [
        {
          header: 'settings.navigation.users.schedules.header',
          hint: 'settings.navigation.users.schedules.hint',
          state: 'settings.schedules',
          name: 'settings.schedules',
        },
        {
          header: 'settings.navigation.users.scheduleExceptions.header',
          hint: 'settings.navigation.users.scheduleExceptions.hint',
          state: 'settings.scheduleExceptions',
          name: 'settings.scheduleExceptions',
        },
        {
          header: 'settings.navigation.timeTracking.periods.header',
          hint: 'settings.navigation.timeTracking.periods.hint',
          state: 'settings.timesheetPeriods',
          name: 'settings.timesheetPeriods',
          entityType: 'TimeSheetPeriod',
        },
        {
          header: 'settings.navigation.timeTracking.activities.header',
          hint: 'settings.navigation.timeTracking.activities.hint',
          state: 'settings.activities',
          name: 'settings.activities',
        },
        {
          header: 'settings.navigation.timeTracking.timeOffTypes.header',
          hint: 'settings.navigation.timeTracking.timeOffTypes.hint',
          state: 'settings.timeOffTypes',
          name: 'settings.timeOffTypes',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.timeOff),
        },
        {
          header: 'settings.navigation.timeTracking.rules.header',
          hint: 'settings.navigation.timeTracking.rules.hint',
          state: 'settings.validationRules',
          name: 'settings.validationRules',
          entityType: 'ValidationRule',
        },
        {
          header: 'settings.navigation.timeTracking.templates.header',
          hint: 'settings.navigation.timeTracking.templates.hint',
          state: 'settings.timesheetTemplates',
          name: 'settings.timesheetTemplates',
          entityType: 'TimeSheetTemplate',
        },
      ],
    },
    {
      name: 'finance',
      header: 'settings.navigation.finance.header',
      items: [
        {
          header: 'settings.navigation.users.legalEntity.header',
          hint: 'settings.navigation.users.legalEntity.hint',
          state: 'settings.legalEntities',
          name: 'settings.legalEntities',
          entityType: 'LegalEntity',
        },
        {
          header: 'settings.navigation.finance.rateMatrices.header',
          hint: 'settings.navigation.finance.rateMatrices.hint',
          state: 'settings.rateMatrices',
          name: 'settings.rateMatrices',
          entityType: 'RateMatrix',
        },
        {
          header: 'settings.navigation.finance.currencies.header',
          hint: 'settings.navigation.finance.currencies.hint',
          state: 'settings.currencies',
          name: 'settings.currencies',
        },
        {
          header: 'shared2.props.financialAccounts.value',
          hint: 'shared2.props.financialAccounts.hint',
          state: 'settings.financialAccounts',
          name: 'settings.financialAccounts',
          entityType: 'FinancialAccount',
        },
        {
          header: 'settings.navigation.finance.invoiceTemplates.header',
          hint: 'settings.navigation.finance.invoiceTemplates.hint',
          state: 'settings.invoiceTemplates',
          name: 'settings.invoiceTemplates',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.billing),
        },
        {
          header: 'settings.navigation.finance.costNormalizationRules.header',
          hint: 'settings.navigation.finance.costNormalizationRules.hint',
          state: 'settings.costNormalizationRules',
          name: 'settings.costNormalizationRules',
          entityType: 'CostNormalizationRule',
        },
      ],
    },
    {
      name: 'tasks',
      header: 'components.settingsAppNavigation.groups.tasks',
      items: [
        {
          header: 'shared2.groups.issueTypes',
          hint: 'shared2.groups.issueTypes',
          state: 'settings.issueTypes',
          name: 'settings.issueTypes',
          entityType: 'IssueType',
        },
        {
          header: 'shared2.groups.issueResolutions',
          hint: 'shared2.groups.issueResolutions',
          state: 'settings.issueResolutions',
          name: 'settings.issueResolutions',
          entityType: 'IssueResolution',
        },
      ],
    },
    {
      name: 'configuration',
      header: 'settings.navigation.configuration.header',
      items: [
        {
          name: 'settings.boards',
          header: 'settings.navigation.configuration.boards.header',
          hint: 'settings.navigation.configuration.boards.hint',
          state: 'settings.boards',
          entityType: 'Board',
        },
        {
          name: 'settings.lifecycles',
          header: 'settings.navigation.configuration.lifecycles.header',
          hint: 'settings.navigation.configuration.lifecycles.hint',
          state: 'settings.lifecycles',
          entityType: 'Lifecycle',
        },
        {
          name: 'settings.workflows',
          header: 'settings.navigation.configuration.workflows.header',
          hint: 'settings.navigation.configuration.workflows.hint',
          state: 'settings.workflows',
          entityType: 'WorkflowDeclaration',
        },
        {
          header: 'shared2.groups.directories',
          hint: 'shared2.groups.directories',
          state: 'settings.directories',
          name: 'settings.directories',
          entityType: 'Directory',
        },
        {
          name: 'settings.customFields',
          header: 'settings.navigation.configuration.customFields.header',
          hint: 'settings.navigation.configuration.customFields.hint',
          state: 'settings.customFields',
          entityType: 'CustomField',
        },
        {
          name: 'settings.metamodel',
          header: 'settings.navigation.configuration.metamodel.header',
          hint: 'settings.navigation.configuration.metamodel.hint',
          state: 'settings.metamodel',
          isVisible: false,
        },
      ],
    },
  ],
};
