import {
  DestroyRef,
  Inject,
  inject,
  Injectable,
  Injector,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { Constants } from 'src/app/shared/globals/constants';

import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Contact } from 'src/app/contacts/model/contact.model';
import { ClientContactsToolbarComponent } from 'src/app/clients/card/client-contact/toolbar/client-contacts-toolbar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from 'src/app/shared/models/entities/projects/client.model';
import { ContactCreateModalComponent } from 'src/app/shared/components/contact-create/contact-create.component';
import { Order } from 'src/app/shared/models/inner/order';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Injectable()
export class ClientContactsService {
  public formArray = this.fb.array([]);
  public readonly = signal<boolean>(false);
  public gridOptions: Grid2Options = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.row,
    toolbar: ClientContactsToolbarComponent,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonly(),
        handlerFn: () => {
          this.createClientContact();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly(),
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteClientContact(formGroup.value.id);
        },
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteClientContact(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  /** Prevents client contact autosaving. */
  public isStopSaving = signal<boolean>(false);

  /** Set whether to display only active contacts */
  public onlyActive = signal<boolean>(true);

  private destroyRef = inject(DestroyRef);
  private contactCollection = this.dataService.collection('Contacts');

  constructor(
    @Inject('organizationId') public organizationId: string,
    private listService: ListService,
    private savingQueueService: SavingQueueService,
    private gridService: GridService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private notificationService: NotificationService,
    private modal: NgbModal,
    private injector: Injector,
    private customFieldService: CustomFieldService,
  ) {
    this.savingQueueService.delayDuration = 0;
    this.gridService.setOrder(this.listService.getGridView().order);
    this.gridService.order$
      .pipe(takeUntilDestroyed())
      .subscribe((o: Order) => this.orderChanged(o));
  }

  /** Loads client contacts. */
  public load(): void {
    this.savingQueueService.save().then(
      () => {
        this.gridService.setLoadingState(true);
        this.formArray.clear();

        const query: any = {
          select: ['id', 'editAllowed', 'contactsEditAllowed'],
          expand: {
            contacts: {
              select: [
                'id',
                'name',
                'firstName',
                'lastName',
                'patronymic',
                'organizationId',
                'roleId',
                'position',
                'description',
                'email',
                'mobilePhone',
                'phone',
              ],
              filter: {},
              orderBy: this.getOrderBy(),
            },
          },
        };

        this.customFieldService.enrichQuery(query.expand.contacts, 'Contact');

        if (this.onlyActive()) {
          query.expand.contacts.filter.isActive = true;
        } else {
          delete query.expand.contacts.filter;
        }

        this.dataService
          .collection('Organizations')
          .entity(this.organizationId)
          .get(query)
          .subscribe({
            next: (client: Partial<Client>) => {
              const contacts = client.contacts;

              contacts.forEach((contact: Contact) => {
                const group = this.getClientContactFormGroup();
                group.patchValue(contact, { emitEvent: false });
                if (!client.contactsEditAllowed) {
                  this.readonly.set(true);
                  group.disable({ emitEvent: false });
                }

                this.formArray.push(group);
              });

              this.gridService.setLoadingState(false);
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
              this.gridService.setLoadingState(false);
            },
          });
      },
      () => null,
    );
  }

  /** Adding a new row to the contacts table. */
  public createClientContact(): void {
    const modalRef = this.modal.open(ContactCreateModalComponent, {
      injector: this.injector,
    });

    (modalRef.componentInstance as ContactCreateModalComponent).organizationId =
      this.organizationId;

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Deletes contact.
   *
   * @param contactId id of deleting contact.
   */
  public deleteClientContact(contactId: string) {
    this.contactCollection
      .entity(contactId)
      .delete()
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === contactId,
          );
          this.formArray.controls.splice(index, 1);
          const groupForSelection =
            this.formArray.controls[index] ??
            this.formArray.controls[index - 1] ??
            null;

          this.gridService.selectGroup(groupForSelection as UntypedFormGroup);

          this.gridService.detectChanges();
          this.notificationService.successLocal('shared.deleteCompleted');
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Generates an update request for a client contacts.
   *
   * @param contact The project cost center to be updated.
   * @returns An observable of the updated client contact.
   */
  public editClientContact(contact: Contact): Observable<Contact> {
    contact.organizationId = this.organizationId;

    return this.contactCollection.entity(contact.id).update(contact);
  }

  /**
   * Returns contact form group.
   *
   * @returns contact form group.
   */
  private getClientContactFormGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      name: [
        null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      organizationId: null,
      roleId: null,
      position: null,
      description: null,
      email: null,
      mobilePhone: null,
      phone: null,
      firstName: null,
      lastName: null,
      patronymic: null,
    });

    this.customFieldService.enrichFormGroup(group, 'Contact');

    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared2.messages.entityNotSaved',
          );
          return;
        }

        this.savingQueueService.addToQueue(
          group.value.id,
          this.editClientContact(group.getRawValue()),
        );
      });

    return group;
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }

  /**
   * Updates the order of the contact view and reloads the list.
   *
   * @param order The new order to be applied.
   */
  public orderChanged(order: Order): void {
    const userView = this.listService.getUserView();
    userView.order = order;
    this.listService.saveUserView(userView);
    this.load();
  }

  /**
   * Gets orderBy for loading.
   *
   * @returns orderBy string.
   */
  private getOrderBy(): string {
    const column = this.gridService.order.column || 'firstName';
    const direction = this.gridService.order.reverse ? 'desc' : 'asc';

    return `${column} ${direction}`;
  }
}
