import { DestroyRef, Inject, Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { Contact } from 'src/app/contacts/model/contact.model';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class ContactCardService {
  private contactNameSubject = new BehaviorSubject<string>(null);
  public contactName$ = this.contactNameSubject.asObservable();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public contactForm = this.fb.group({
    firstName: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    lastName: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    patronymic: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    organization: null,
    roleId: null,
    position: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    email: [
      '',
      [Validators.maxLength(Constants.formTextMaxLength), Validators.email],
    ],
    mobilePhone: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    phone: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    isActive: null,
  });
  public isLoading = signal<boolean>(false);
  public isSaving = signal<boolean>(false);
  public readonly: boolean;
  public contact: Contact;

  private destroyRef = inject(DestroyRef);
  private contactCollection = this.dataService.collection('Contacts');

  constructor(
    @Inject('entityId')
    private contactId: string,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private navigationService: NavigationService,
    private customFieldService: CustomFieldService,
    private savingQueueService: SavingQueueService,
    private notificationService: NotificationService,
  ) {
    this.customFieldService.enrichFormGroup(this.contactForm, 'Contact');
    this.subscribeToFormChanges();
  }

  /** Contact loading. */
  public load(): void {
    this.savingQueueService.save().then(
      () => {
        this.contactForm.markAsPristine();
        this.contactForm.markAsUntouched();

        this.isLoading.set(true);

        const query = {
          select: [
            'name',
            'firstName',
            'lastName',
            'patronymic',
            'organizationId',
            'roleId',
            'position',
            'description',
            'email',
            'mobilePhone',
            'phone',
            'editAllowed',
            'isActive',
          ],
          expand: { organization: { select: ['id', 'name'] } },
        };

        this.customFieldService.enrichQuery(query, 'Contact');

        this.contactCollection
          .entity(this.contactId)
          .get<Contact>(query)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (contact: Contact) => {
              this.contactForm.patchValue(contact, { emitEvent: false });
              this.contact = { ...contact, id: this.contactId };
              this.isLoading.set(false);
              this.readonly = !contact.editAllowed;
              this.readonly
                ? this.contactForm.disable({ emitEvent: false })
                : this.contactForm.enable({ emitEvent: false });
              this.state$.next(CardState.Ready);
              this.contactNameSubject.next(contact.name);
              this.navigationService.addRouteSegment({
                id: this.contactId,
                title: contact.name,
              });
            },
            error: (error: Exception) => {
              this.isLoading.set(false);
              this.state$.next(CardState.Error);
              this.notificationService.error(error.message);
            },
          });
      },
      () => null,
    );
  }

  /** Saving when changing the form. */
  private subscribeToFormChanges(): void {
    this.contactForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const contactData = this.contactForm.getRawValue();
        this.contact = { ...this.contact, ...contactData };
        this.contact.organizationId = this.contact.organization.id;
        this.contactNameSubject.next(
          `${this.contact.firstName} ${this.contact.lastName}`,
        );
        this.savingQueueService.addToQueue(
          this.contactId,
          this.contactCollection
            .entity(this.contactId)
            .update(this.contact)
            .pipe(
              tap(() => {
                this.contactForm.markAsPristine();
              }),
            ),
        );
      });
  }
}
