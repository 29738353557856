import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectCardService } from '../../core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import {
  BehaviorSubject,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { DateTime } from 'luxon';
import {
  Analytics,
  analytics,
} from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import _ from 'lodash';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import {
  BoxControlComponent,
  CascadeDependency,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { GenericSettings } from 'src/app/projects/card/project-team/generic-modal/model/generic-setting.model';
import { CodedEntity } from 'src/app/shared/models/entities/coded-entity.model';

@Component({
  selector: 'wp-generic-modal',
  templateUrl: './generic-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericModalComponent implements OnInit, OnDestroy {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  @Input() editAllowed = true;

  @Input() resourceId: string;
  @Input() teamMemberId: string;
  @Input() existingNames: string[];

  public isLoading$ = new BehaviorSubject(false);
  private _hasResourceRequest = false;
  public get hasResourceRequest() {
    return this._hasResourceRequest;
  }
  public set hasResourceRequest(val) {
    this._hasResourceRequest = val;
    if (val) {
      this.form.disable();
      this.form.controls.name.enable();
    }
  }
  public mode: 'create' | 'edit' | 'createFromUser';

  public strictFillOut = new UntypedFormControl(false);

  /** Observables, передающиеся в контролы, по которым при открытии контрола загружаются значения. */
  public levels$: Observable<NamedEntity[]>;
  public grades$: Observable<NamedEntity[]>;

  public isSaving: boolean;

  public form = this.fb.group({
    role: null,
    level: null,
    name: [null, [Validators.required, Validators.maxLength(100)]],
    resourcePool: null,
    grade: null,
    location: null,
    costRate: 0,
    staffCount: null,
    primaryTariff: null,
    competence: null,
    legalEntity: null,
    employmentType: null,
  });
  public matrixAnalytics: Analytics[] = [];
  public systemDirectory = SystemDirectory;
  public get otherAnalytics(): Analytics[] {
    return analytics.filter(
      (analytic) => !this.matrixAnalytics.includes(analytic),
    );
  }

  private settings: GenericSettings;
  private stopAutoName$ = new Subject<void>();
  private destroyRef = inject(DestroyRef);
  private stopCascadeControls$ = new Subject<void>();
  private autoNameSubscription: Subscription[] = [];

  constructor(
    @Inject('entityId') public projectId: string,
    public projectCardService: ProjectCardService,
    public projectTeamService: ProjectTeamService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private localConfigService: LocalConfigService,
  ) {}

  public ngOnInit(): void {
    this.settings = this.localConfigService.getConfig(GenericSettings);
    this.strictFillOut.setValue(
      this.localConfigService.getConfig(GenericSettings).strictFillOut,
    );

    this.isLoading$.next(true);
    this.initModal();

    this.form.controls.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        if (!val) {
          this.subToAutoName();
        } else {
          this.stopAutoName$.next();
        }
      });
  }

  public ngOnDestroy(): void {
    this.stopAutoName$.next();
    this.stopCascadeControls$.next();
  }

  /**
   * Handles the 'OK' action of the modal which involves form submission.
   * Depending on the context (add more or just save), it either creates a new generic resource
   * or updates an existing one.
   *
   * @param {boolean} addMore - Determines whether to add more items after saving or just save and close.
   */
  public ok(addMore: boolean): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const formValues = this.form.getRawValue();
    const genericResource = {
      roleId: formValues.role?.id ?? null,
      levelId: formValues.level?.id ?? null,
      resourcePoolId: formValues.resourcePool?.id ?? null,
      name: formValues.name,
      gradeId: formValues.grade?.id ?? null,
      locationId: formValues.location?.id ?? null,
      competenceId: formValues.competence?.id ?? null,
      legalEntityId: formValues.legalEntity?.id ?? null,
      employmentTypeId: formValues.employmentType?.id ?? null,
    };

    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      genericResource,
      this.projectId,
    );

    if (this.mode === 'edit') {
      const teamMemberPatchRequest = this.data
        .collection('ProjectTeamMembers')
        .entity(this.teamMemberId)
        .patch({
          roleId: formValues.role?.id ?? null,
          primaryTariffId: formValues.primaryTariff?.id ?? null,
        });

      const genericPatchRequest = this.data
        .collection('Generics')
        .entity(this.resourceId)
        .patch(genericResource);

      forkJoin({
        teamMemberPatchRequest,
        genericPatchRequest,
      }).subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal('shared.messages.saved');
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.cdr.detectChanges();
        },
      });
    } else {
      this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .action('CreateGeneric')
        .execute({
          generic: genericResource,
          primaryTariffId: formValues.primaryTariff?.id,
        })
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.notification.successLocal(
              'projects.projects.card.team.messages.membersWereAdded',
            );

            if (addMore) {
              this.projectCardService.reloadTab();
              this.existingNames.push(this.form.controls.name.value);
              this.cdr.detectChanges();
            } else {
              this.activeModal.close();
            }
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
            this.cdr.detectChanges();
          },
        });
    }
  }

  /** Dismisses the active modal without saving any changes. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Retrieves strict values for a given analytic.
   *
   * This function constructs a query to fetch strict values for the specified analytic.
   * It filters the results based on the selected analytics and groups them by the analytic's id and name.
   *
   * @param analytic The analytic for which to retrieve strict values.
   * @returns An observable that emits an array of CodedEntity objects representing the strict values for the given analytic.
   */
  public getStrictValues(analytic: Analytics): Observable<CodedEntity[]> {
    const query: any = {
      transform: {
        filter: [
          {
            [analytic]: {
              id: { ne: { type: 'guid', value: null } },
            },
          },
        ],
        groupBy: {
          properties: [
            `${analytic}/id`,
            `${analytic}/name`,
            `${analytic}/code`,
          ],
        },
      },
    };

    const analyticIndex = this.matrixAnalytics.findIndex((a) => a === analytic);
    const selectedAnalytics = this.matrixAnalytics.filter(
      (a, index) => index < analyticIndex,
    );

    // Iterate over the selected analytics and add their values to the query filter
    selectedAnalytics.forEach((a) => {
      const value = this.form.controls[a].value?.id;
      if (!value) return;
      query.transform.filter.push({
        [a]: {
          id: { type: 'guid', value },
        },
      });
    });

    return this.data
      .collection('RateMatrixLines')
      .query(query)
      .pipe(
        map((res: Record<string, CodedEntity>[]) =>
          res.map((val) => ({
            id: val[analytic]?.id,
            name: val[analytic]?.name ?? '',
            code: val[analytic]?.code ?? '',
          })),
        ),
      );
  }

  /**
   * Returns the collection name for the given analytic.
   *
   * @param analytic The analytic for which to retrieve the collection name.
   * @returns The collection name for the given analytic.
   */
  public getMatrixAnalyticCollection(analytic: string): string {
    return analytic === 'legalEntity'
      ? 'LegalEntities'
      : _.upperFirst(analytic) + 's';
  }

  /**
   * Initializes the modal with default values or existing project team member data.
   * If not in editing mode, it loads default values for the form controls.
   * If in editing mode, it loads the existing project team member data into the form controls.
   */
  private initModal(): void {
    if (this.mode === 'create') {
      this.getActualMatrixStructure()
        .pipe(switchMap(() => this.loadDefaults()))
        .subscribe({
          next: (response) => {
            if (
              (!this.strictFillOut.value ||
                !this.matrixAnalytics.includes('resourcePool')) &&
              response.pools.length === 1
            ) {
              this.form.controls.resourcePool.patchValue(response.pools[0]);
            }
            if (
              (!this.strictFillOut.value ||
                !this.matrixAnalytics.includes('level')) &&
              response.levels.length === 1
            ) {
              this.form.controls.level.patchValue(response.levels[0]);
            }

            if (this.matrixAnalytics.length) {
              this.initStrictFillOut();
            }

            this.initUpdateGenericCalculatedInfoSubscriptions();

            this.isLoading$.next(false);

            this.setCascadeControls();

            this.subToAutoName();
          },
          error: () => {
            this.isLoading$.next(false);
          },
        });
    } else {
      this.getActualMatrixStructure()
        .pipe(
          switchMap(() => this.getProjectTeamMember()),
          switchMap((teamMember) => {
            this.form.patchValue(teamMember.resource);
            if (this.mode === 'createFromUser') {
              this.form.controls.name.patchValue(null);
            }
            this.form.controls.primaryTariff.patchValue(
              teamMember.primaryTariff,
            );
            if (this.matrixAnalytics.length) {
              this.initStrictFillOut();
            }
            if (!this.editAllowed) {
              this.form.disable();
            }

            return this.generateName(this.mode === 'edit');
          }),
        )
        .subscribe({
          next: (name) => {
            if (name && this.form.controls.name.value.includes(name)) {
              this.subToAutoName();
            }
            this.initUpdateGenericCalculatedInfoSubscriptions();
            this.isLoading$.next(false);
            this.setCascadeControls();
          },
          error: () => {
            this.isLoading$.next(false);
          },
        });
    }
  }

  /** Initializes subscriptions to form control value changes to update generic calculated information. */
  private initUpdateGenericCalculatedInfoSubscriptions() {
    this.updateGenericCalculatedInfo();
    merge(
      this.form.controls.location.valueChanges,
      this.form.controls.grade.valueChanges,
      this.form.controls.level.valueChanges,
      this.form.controls.role.valueChanges,
      this.form.controls.resourcePool.valueChanges,
      this.form.controls.competence.valueChanges,
      this.form.controls.legalEntity.valueChanges,
      this.form.controls.employmentType.valueChanges,
    )
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateGenericCalculatedInfo();
      });
  }

  /** Updates the generic calculated information based on the form control values. This includes staff count and cost rate. */
  private updateGenericCalculatedInfo(): void {
    const location = this.form.controls.location.getRawValue();
    const level = this.form.controls.level.getRawValue();
    const grade = this.form.controls.grade.getRawValue();
    const role = this.form.controls.role.getRawValue();
    const resourcePool = this.form.controls.resourcePool.getRawValue();
    const competence = this.form.controls.competence.getRawValue();
    const legalEntity = this.form.controls.legalEntity.getRawValue();
    const employmentType = this.form.controls.employmentType.getRawValue();

    const params: Record<string, any> = {
      filter: '@filter',
    };
    const filterObject = {
      roleId: role?.id || null,
      levelId: level?.id || null,
      gradeId: grade?.id || null,
      locationId: location?.id || null,
      resourcePoolId: resourcePool?.id || null,
      competenceId: competence?.id || null,
      legalEntityId: legalEntity?.id || null,
      employmentTypeId: employmentType?.id || null,
    };
    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
    };

    this.data
      .collection('ProjectTeamMembers')
      .function('GetGenericCalculatedInfo')
      .get<{ staffCount: number | null; costRate: number | null }>(
        params,
        null,
        urlParams,
      )
      .subscribe((val) => {
        const staffCount = val.staffCount;
        const staffCountTitle = StringHelper.declOfNum(staffCount, [
          this.translate.instant('shared.employeeDeclensions.v1'),
          this.translate.instant('shared.employeeDeclensions.v2'),
          this.translate.instant('shared.employeeDeclensions.v3'),
        ]);
        const parsedStaffCount = staffCount + ' ' + staffCountTitle;

        this.form.controls.staffCount.patchValue(parsedStaffCount);
        this.form.controls.costRate.patchValue(val.costRate);
      });
  }

  /**
   * Loads the default resource pools and levels.
   *
   * @returns An observable that emits an object containing arrays of NamedEntity objects for pools and levels.
   */
  private loadDefaults(): Observable<{
    pools: NamedEntity[];
    levels: NamedEntity[];
  }> {
    return forkJoin({
      pools: this.data
        .collection('ResourcePools')
        .query<NamedEntity[]>({ filter: { isDefault: true, isActive: true } }),
      levels: this.data
        .collection('Levels')
        .query<NamedEntity[]>({ filter: { isActive: true } }),
    });
  }

  /**
   * Retrieves the actual matrix structure for rate matrices.
   *
   * @returns An observable that emits a partial array of RateMatrix objects representing the actual matrix structure.
   */
  private getActualMatrixStructure(): Observable<Partial<RateMatrix[]>> {
    const today = DateTime.now().toISODate();
    const query = {
      filter: {
        typeId: { type: 'guid', value: RateMatrix.costRateTypeId },
        and: [
          {
            or: [
              { effectiveDate: { type: 'raw', value: 'null' } },
              { effectiveDate: { le: { type: 'raw', value: today } } },
            ],
          },
          {
            or: [
              { expiryDate: { ge: { type: 'raw', value: today } } },
              { expiryDate: { type: 'raw', value: 'null' } },
            ],
          },
        ],
        stateId: { type: 'guid', value: RateMatrix.activeStateId },
      },
      orderBy: 'effectiveDate',
    };

    return this.data
      .collection('RateMatrices')
      .query(query)
      .pipe(
        tap((matrices: RateMatrix[]) => {
          this.matrixAnalytics = matrices.length
            ? matrices[0].rateMatrixStructure.map(
                (item) => (item = _.lowerFirst(item) as Analytics),
              )
            : [];
        }),
      );
  }

  /**
   * Retrieves the project team member data along with related entities.
   *
   * @returns An observable that emits the project team member data.
   */
  private getProjectTeamMember(): Observable<ProjectTeamMember> {
    const query = {
      expand: {
        resource: {
          select: ['*'],
          expand: {
            grade: {
              select: ['id', 'name', 'levelId', 'code'],
            },
            competence: {
              select: ['id', 'name', 'code'],
            },
            legalEntity: {
              select: ['id', 'name', 'code'],
            },
            level: {
              select: ['id', 'name', 'code'],
            },
            resourcePool: {
              select: ['id', 'name', 'code'],
            },
            location: {
              select: ['id', 'name', 'code'],
            },
            role: {
              select: ['id', 'name', 'code'],
              filter: [{ isActive: true }],
            },
            employmentType: {
              select: ['id', 'name'],
              filter: [{ isActive: true }],
            },
          },
        },
        primaryTariff: {
          select: ['id', 'name'],
        },
      },
    };

    return this.data
      .collection('ProjectTeamMembers')
      .entity(this.teamMemberId)
      .get<ProjectTeamMember>(query);
  }

  /** Sets up cascading dependencies between form controls. */
  private setCascadeControls(): void {
    this.cdr.detectChanges();
    this.stopCascadeControls$.next();
    const dependencies: CascadeDependency[] = [];
    if (
      !this.strictFillOut.value ||
      this.matrixAnalytics.every(
        (analytic) => analytic !== 'role' && analytic !== 'competence',
      )
    ) {
      dependencies.push([
        {
          controlName: 'role',
        },
        {
          controlName: 'competence',
          dependedProperty: 'roleId',
        },
      ]);
    }

    if (
      !this.strictFillOut.value ||
      this.matrixAnalytics.every(
        (analytic) => analytic !== 'level' && analytic !== 'grade',
      )
    ) {
      dependencies.push([
        {
          controlName: 'level',
        },
        { controlName: 'grade', dependedProperty: 'levelId' },
      ]);
    }
    FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      dependencies,
      takeUntil(this.stopCascadeControls$),
    );
  }

  /**
   * Generates name depending on filled matrix analytics.
   *
   * @param initEditing  shows whether first init of modal editing.
   * @returns String observable for switchMap.
   */
  private generateName(initEditing?: boolean): Observable<string> {
    const filledControls = [];

    this.matrixAnalytics.forEach((analytic) => {
      if (this.form.controls[analytic].value) {
        filledControls.push(this.form.controls[analytic]);
      }
    });

    let generatedName = '';

    filledControls.forEach((a, index) => {
      const analytic = filledControls[index]?.value;
      if (!index) {
        generatedName = analytic?.name;
        return;
      }
      generatedName += ` ${analytic.code?.length ? analytic?.code : analytic?.name}`;
    });

    if (initEditing) {
      return of(generatedName);
    }

    this.form.controls.name.patchValue(this.getUniqueName(generatedName), {
      emitEvent: false,
    });

    const uniqueName = this.getUniqueName(generatedName);

    return of(uniqueName);
  }

  /** Subscribes to controls of matrix analytics and calls generateName function. */
  private subToAutoName(): void {
    this.autoNameSubscription.forEach((subscription) =>
      subscription.unsubscribe(),
    );
    this.matrixAnalytics.forEach((a, index) => {
      this.autoNameSubscription.push(
        this.form.controls[this.matrixAnalytics[index]].valueChanges
          .pipe(
            takeUntil(this.stopAutoName$),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe(() => {
            this.generateName();
          }),
      );
    });
  }

  /**
   * Function returns newGeneratedName if generated name is not unique.
   *
   * @param generatedName name generated by function generateName.
   * @returns newGeneratedName or generatedName.
   */
  private getUniqueName(generatedName: string): string {
    let nameExists = !!this.existingNames.find(
      (name) => name === generatedName,
    );

    let newGeneratedName;
    let i = 2;
    while (nameExists) {
      newGeneratedName = `${generatedName} (${i})`;
      nameExists = !!this.existingNames.find(
        (name) => name === newGeneratedName,
      );
      i++;
    }

    return newGeneratedName ?? generatedName;
  }

  /** Initializes the strict fill-out functionality. */
  private initStrictFillOut(): void {
    this.strictFillOut.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.settings.strictFillOut = val;
        this.localConfigService.setConfig(GenericSettings, this.settings);
        if (val) {
          this.initStrictFillOutControls();
        } else {
          this.matrixAnalytics.forEach((analytic) =>
            this.form.controls[analytic].enable(),
          );
        }
        this.setCascadeControls();
      });

    if (this.strictFillOut.value) {
      this.initStrictFillOutControls(this.mode === 'create');
    }
  }

  /**
   * Initializes the strict fill-out controls.
   *
   * @param {boolean} resetControls - Determines whether to reset the controls to their initial state. Defaults to true.
   */
  private initStrictFillOutControls(resetControls = true): void {
    if (!this.matrixAnalytics.length) return;

    this.matrixAnalytics.forEach((analytic, index) => {
      // Subscribe to value changes of the current analytic control
      this.form.controls[analytic].valueChanges
        .pipe(
          filter(() => this.strictFillOut.value),
          // Unsubscribe when strictFillOut is set to false
          takeUntil(this.strictFillOut.valueChanges.pipe(filter((x) => !x))),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((value) => {
          // Reset the values of subsequent analytic controls
          for (let i = index + 1; i < this.matrixAnalytics.length; i++) {
            this.form.controls[this.matrixAnalytics[i]].setValue(null, {
              emitEvent: false,
            });
          }

          // If this is the last analytic, return early
          if (index === this.matrixAnalytics.length - 1) return;

          // Enable or disable the next analytic control based on the current value
          if (value) {
            this.form.controls[this.matrixAnalytics[index + 1]].enable();
          } else {
            this.form.controls[this.matrixAnalytics[index + 1]].disable();
          }
        });

      if (resetControls) {
        this.form.controls[this.matrixAnalytics[index]].setValue(null, {
          emitEvent: false,
        });
      }

      if (
        index > 0 &&
        (resetControls ||
          !this.form.controls[this.matrixAnalytics[index - 1]].value ||
          this.form.controls[this.matrixAnalytics[index - 1]].disabled)
      ) {
        this.form.controls[this.matrixAnalytics[index]].disable({
          emitEvent: false,
        });
      }
    });
  }
}
