import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { map, shareReplay } from 'rxjs/operators';
import { FinancialAccount } from '../shared/models/entities/finance/financial-account.model';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';

@Injectable({ providedIn: 'root' })
export class FinancialAccountsService {
  constructor(private data: DataService) {}

  /** Загруженный список видов работ, доступных для автора текущего таймшита. */
  private accounts: ReadonlyArray<FinancialAccount>;

  public accounts$ = this.getAvailableAccounts().pipe(shareReplay());
  public expensesRequestAccounts$ = this.accounts$.pipe(
    map((accounts) =>
      accounts.filter(
        (account) =>
          !FinancialAccount.laborCostLike.includes(account.id) &&
          FinancialAccount.corporateTaxId !== account.id &&
          account.type.id === FinancialAccountType.expenses.id,
      ),
    ),
    shareReplay(),
  );

  /** Возвращает список доступных видов работ.  */
  private getAvailableAccounts(): Observable<ReadonlyArray<FinancialAccount>> {
    return new Observable((subscriber) => {
      if (this.accounts) {
        subscriber.next(this.accounts);
      }

      this.data
        .collection('FinancialAccounts')
        .query<FinancialAccount[]>({
          select: ['id', 'name'],
          filter: { isActive: true },
          expand: {
            type: {
              select: ['id', 'code', 'name'],
            },
          },
        })
        .subscribe((accounts) => {
          this.accounts = accounts;
          subscriber.next(this.accounts);
        });
    });
  }
}
