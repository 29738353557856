/* eslint-disable @typescript-eslint/naming-convention */
/** Represents PnL statement entry kind. */
export enum PnlStatementEntryKind {
  /** Unspecified entry kind. */
  Unspecified = 'Unspecified',
  /** Revenue entry kind. */
  Revenue = 'Revenue',
  /** Labor Cost entry kind. */
  LaborCost = 'LaborCost',
  /** Expenses entry kind. */
  Expenses = 'Expenses',
  /** Inner Expense Line entry kind. */
  InnerExpenseLine = 'InnerExpenseLine',
  /** Expense Rule entry kind. */
  ExpenseRule = 'ExpenseRule',
  /** Time Off Cost entry kind. */
  TimeOffCost = 'TimeOffCost',
  /** Working Capital entry kind. */
  WorkingCapital = 'WorkingCapital',
  /** Outstanding cost entry kind. */
  OutstandingCost = 'OutstandingCost',
  /** Cost of capital entry kind. */
  CostOfCapital = 'CostOfCapital',
  /** Corporate tax entry kind. */
  CorporateTax = 'CorporateTax',
}
