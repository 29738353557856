@if (readonly) {
  <p
    class="trim text-readonly"
    [class.text-body-secondary]="!selectedRow?.name"
    [title]="getReadOnlyDisplayText()"
  >
    {{ getReadOnlyDisplayText() }}
  </p>
} @else {
  <div
    class="input-group"
    [class.nullable]="allowNull"
    (clickOutside)="cancel()"
    [attachOutsideOnClick]="true"
    wpIntersectable
    (onIntersect)="cancel()"
    radiogroup
  >
    <input
      #input
      class="form-control"
      type="text"
      [formControl]="textControl"
      [title]="textControl?.value"
      [value]="textControl?.value"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
      (click)="onInputClick()"
    />

    <span
      class="input-clear"
      [class.has-sub]="withHierarchy"
      [title]="'shared2.actions.clearValue' | translate"
      [hidden]="!textControl.value"
      (click)="clear()"
      >&times;
    </span>

    @if (withHierarchy) {
      <button
        type="button"
        class="btn btn-default input-group-text"
        [ngClass]="{ active: includeSubElements }"
        (click)="setIncludingSubElements()"
        title="{{ 'shared2.actions.includeSubElements' | translate }}"
        data-test="includeSubordinates"
      >
        <i class="bi bi-diagram-3" aria-hidden="true"></i>
      </button>
    }

    <div class="select-expand-container" #expandingArea>
      @if (listOpened) {
        <div class="fixed-expanding-area scroll-y-overflow">
          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

          @if (!rows.length && !isLoading) {
            <div class="center-block">
              <p class="text-body-secondary text-center p-1">
                {{ 'shared.noOptions' | translate }}
              </p>
            </div>
          }

          @if (rows.length) {
            <tmt-tree-list
              [itemsPlane]="rows"
              [parentIdKey]="parentIdKey"
              [target]="treeListTarget"
              [selectedItem]="selectedRow"
              (selected$)="onSelected($event)"
              (keyDown$)="onKeyDown($event)"
              (focused$)="onFocused($event)"
            />
          }
        </div>
      }
    </div>

    <button
      tabindex="-1"
      type="button"
      class="btn btn-default input-group-text"
      (click)="toggleList()"
    >
      <i class="bi bi-chevron-down" aria-hidden="true"></i>
    </button>
  </div>
}
