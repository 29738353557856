import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { FINANCIAL_ACCOUNT_LIST } from 'src/app/shared/lists/expense-type.list';

import { FinancialAccountCardComponent } from './card/financial-account-card.component';
import { FinancialAccountCreationComponent } from './creation/financial-account-creation.component';
import { FinancialAccountListComponent } from './list/financial-account-list.component';

@NgModule({
  declarations: [
    FinancialAccountListComponent,
    FinancialAccountCardComponent,
    FinancialAccountCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.financialAccounts',
          url: '/financial-accounts',
          component: FinancialAccountListComponent,
          params: {
            navigation: 'settings.financialAccounts',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: FINANCIAL_ACCOUNT_LIST },
          ],
        },

        {
          name: 'settings.financialAccount',
          params: {
            navigation: 'settings.financialAccounts',
          },
          url: '/financial-accounts/{entityId:guid}',
          component: FinancialAccountCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class FinancialAccountModule {}
