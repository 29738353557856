import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';

// eslint-disable-next-line max-len
import { ProjectExpenseEstimatesToolbarComponent } from './project-expense-estimates-toolbar/project-expense-estimates-toolbar.component';
import { ProjectExpenseEstimatesComponent } from './project-expense-estimates.component';
import { ProjectExpenseEstimatesList } from 'src/app/shared/lists/project-expense-estimates.list';
import { ProjectCardSharedModule } from '../../shared/project-card-shared.module';
import { ProjectExpenseTypeReadonlyCellComponent } from './shared/project-expense-type-cell/project-expense-type-readonly-cell.component';
import { ExpenseEstimateModalComponent } from './shared/expense-estimate-modal/expense-estimate-modal.component';
import { ProjectExpenseTypeCellComponent } from './shared/project-expense-type-cell/project-expense-type-cell.component';
import { ProjectExpensesSharedModule } from '../shared/project-expenses-shared.module';

@NgModule({
  declarations: [
    ProjectExpenseEstimatesComponent,
    ProjectExpenseEstimatesToolbarComponent,
    ExpenseEstimateModalComponent,
    ProjectExpenseTypeCellComponent,
    ProjectExpenseTypeReadonlyCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    ProjectExpensesSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.expenses.estimates',
          url: '/estimates',
          component: ProjectExpenseEstimatesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ProjectExpenseEstimatesList },
          ],
        },
      ],
    }),
  ],
})
export class ProjectExpenseEstimatesModule {}
